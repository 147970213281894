import React, { useState, useEffect }  from "react"
import styled from "styled-components"
import { Button } from "react-bootstrap"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import {VISITOR_SUBSCRIPTION, VISITOR_DONATION, VISITOR_PURCHASE} from "../../utils/constants"

const axios = require('axios').default;

const _ = require('lodash');

const TextContainer = styled.div`
  margin: 0;
  padding: 0.25rem;
`

const LeftAlignContainer = styled.div`
  text-align: left;
`

const SubHeader = styled.span`
  font-size: 0.7em;
  margin-left: 1em;
`

const RecipientsLink = styled.p`
  font-size: 0.7em;
`

const isNewUser = function(userData) {
  return _.isEmpty(userData);
}

const isReturningUser = function(userData) {
  return _.isEmpty(userData);
}

const StoreFrontUser = ({ userData, userEmail, displayPasswordLogin=false,
                          faultyUsernameMessage="",
                          faultyEmailMessage="",
                          faultyPasswordMessage = "",
                          setEmailCallback,
                          setPasswordCallback,
                          setUsernameCallback,
                          visitorType=VISITOR_SUBSCRIPTION }) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [selectUsername, setSelectUsername] = useState(false);
  const [password, setPassword] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const [isReturningUser, setIsReturningUser] = useState(false);

  useEffect(() => {
    console.log("set Email");
    console.log(userEmail);
    console.log("userData is");
    console.log(userData);
    setEmail(userEmail);

    if (!_.isEmpty(userEmail) && displayPasswordLogin) {
      console.log("AAA");
      setIsNewUser(false);
      setIsReturningUser(true);
    } else if (_.isEmpty(userData)) {
      console.log("BBB");
      setIsNewUser(true);
      setIsReturningUser(false);
    } else {
      console.log("CCC");
      setIsNewUser(false);
      setIsReturningUser(false);
    }

  }, [userEmail, userData, displayPasswordLogin])

  const handleEmailChange = function(event) {
    setEmail(event.target.value);
    setEmailCallback(event.target.value);
  }

  const handleUsernameChange = function(event) {
    setUsername(event.target.value);
    setUsernameCallback(event.target.value);
  }

  const handlePasswordChange = function(event) {
    setPassword(event.target.value);
    setPasswordCallback(event.target.value);
  }

  const infoText = function() {
   return `Ange din e-postadress så får du ett betalkonto som du kan använda för snabba (5 s) betalningar, från 1 kr och 
    uppåt, av prenumerationer, tjänster och donationer till mottagare som är anslutna till MediaLinq. Du fyller på 
    kontot med Swish eller bankgiro. Kredit ges i vissa fall. Om du redan har ett konto hos MediaLinq ska du fylla i 
    din e-postadress och sedan klicka på den gröna bekräftaknappen.`
  }

  const welcomeText = function(visitorType) {

    switch (visitorType) {
      case VISITOR_PURCHASE:
        return "För att göra ett köp, börja med att fylla i din epostadress och klicka sedan på den gröna knappen.";
      case VISITOR_SUBSCRIPTION:
        return "För att prenumerera, börja med att fylla i din epostadress och välj sedan typ av prenumeration.";
      case VISITOR_DONATION:
        return "För att donera, fyll i din e-post och klicka på Bekräfta beloppet nedan. " +
          "Första gången får du ett mejl där du accepterar ditt medlemskap och sedan " +
          "kan du donera eller prenumerera med bara två klick. Du kan göra automatiska månadsdonationer " +
          "och du kan få kredit så att du inte behöver fylla på ditt konto omedelbart. ";
      default:
        return "";
    }
  }


    if (isNewUser) {
      return (
        <LeftAlignContainer>
          <h2>Välkommen till MediaLinq <SubHeader>För enkla och snabba mikrobetalningar</SubHeader></h2>
          <p>{welcomeText(visitorType)}</p>
          <p>{infoText()}</p>
          <InputGroup className="mb-3 mt-4">
            <InputGroup.Prepend>
              <InputGroup.Text id="user-email">E-post</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="E-post"
              aria-label="E-post"
              aria-describedby="user-email"
              onChange={handleEmailChange}
              isInvalid={!_.isEmpty(faultyEmailMessage)}
            />
            <FormControl.Feedback type="invalid">Du måste ange en giltig epostadress</FormControl.Feedback>
          </InputGroup>
          {selectUsername ?
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="username">Användarnamn</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Användarnamn"
              aria-label="Användarnamn"
              aria-describedby="username"
              onChange={handleUsernameChange}
              isInvalid={!_.isEmpty(faultyUsernameMessage)}
            />
            <FormControl.Feedback type="invalid">Du måste ange ett giltigt användarnamn</FormControl.Feedback>
          </InputGroup>
          :
            <div className="mb-3">
              <Button
                onClick={() => setSelectUsername(true)}
                variant="secondary"
                size="sm">Donera anonymt?</Button> Klicka för att välja ett användarnamn - (Frivilligt och gäller endast donationer)</div>}
        </LeftAlignContainer>
      )
    } else if (isReturningUser) {
      return (
        <LeftAlignContainer>
          <p>Välkommen tillbaka {userEmail}!</p>
          <p>Logga in för att slutföra {visitorType === VISITOR_SUBSCRIPTION ? " din prenumeration" : " ditt köp"}. Ange ditt lösenord nedan.</p>

          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="user-password">Lösenord</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type={"password"}
              aria-describedby="user-password"
              onChange={handlePasswordChange}
              isInvalid={!_.isEmpty(faultyPasswordMessage)}
            />
            <FormControl.Feedback type="invalid">Felaktigt lösenord</FormControl.Feedback>
          </InputGroup>

      </LeftAlignContainer>)
    } else {

      return (
        <LeftAlignContainer>
          Hej {userEmail}
        </LeftAlignContainer>
      )
    }

}
export default StoreFrontUser
