// extracted by mini-css-extract-plugin
export var b_r_high = "main-module--b_r_high--wOZdb";
export var b_r_low = "main-module--b_r_low--iQTG6";
export var b_r_mid = "main-module--b_r_mid--DHKj+";
export var b_r_reset = "main-module--b_r_reset---1owA";
export var b_r_subscribe = "main-module--b_r_subscribe--HAnph";
export var b_r_subscribe_wider = "main-module--b_r_subscribe_wider--QTj9P";
export var boldinfo = "main-module--boldinfo--3nJst";
export var buttonContainer = "main-module--buttonContainer--2Qdkj";
export var buttonconfirmtext = "main-module--buttonconfirmtext--xU-9e";
export var controlbutton = "main-module--controlbutton--BHa2B";
export var donationCard = "main-module--donationCard--xaoFG";
export var recurringText = "main-module--recurringText--XMqo8";
export var resetButtonContainer = "main-module--resetButtonContainer--7J8I3";
export var submitButtonContainer = "main-module--submitButtonContainer--qQRKD";
export var subscriptionButtonWrapper = "main-module--subscriptionButtonWrapper--oVo3Q";
export var sumContainer = "main-module--sumContainer--DvebJ";
export var textCenter = "main-module--text-center--oT9R4";
export var textLeft = "main-module--text-left--oyuvZ";