// extracted by mini-css-extract-plugin
export var b_r_donate = "main-module--b_r_donate--413JN";
export var b_r_donate_wider = "main-module--b_r_donate_wider--ZCXr9";
export var b_r_high = "main-module--b_r_high--mq2vj";
export var b_r_low = "main-module--b_r_low--4dIXg";
export var b_r_mid = "main-module--b_r_mid--wdzpo";
export var b_r_reset = "main-module--b_r_reset--Y32mw";
export var boldinfo = "main-module--boldinfo--5Etns";
export var buttonContainer = "main-module--buttonContainer--II73g";
export var buttonExplanationTextContainer = "main-module--buttonExplanationTextContainer--AsSWH";
export var buttonconfirmtext = "main-module--buttonconfirmtext--VJKSV";
export var controlbutton = "main-module--controlbutton--Hq0W-";
export var donationCard = "main-module--donationCard--gXUTg";
export var resetButtonContainer = "main-module--resetButtonContainer--Ix+7K";
export var submitButtonContainer = "main-module--submitButtonContainer--QSuPb";
export var sumContainer = "main-module--sumContainer--nhG9F";