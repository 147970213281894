// extracted by mini-css-extract-plugin
export var b_r_donate = "main-module--b_r_donate--5umYb";
export var b_r_donate_wider = "main-module--b_r_donate_wider--yQxqa";
export var b_r_high = "main-module--b_r_high--StXQK";
export var b_r_low = "main-module--b_r_low--qaFKj";
export var b_r_mid = "main-module--b_r_mid--FDggS";
export var b_r_reset = "main-module--b_r_reset--oY0uE";
export var boldinfo = "main-module--boldinfo--qNKmu";
export var buttonContainer = "main-module--buttonContainer--KlqEx";
export var buttonExplanationTextContainer = "main-module--buttonExplanationTextContainer--zFoMr";
export var buttonconfirmtext = "main-module--buttonconfirmtext--QmNRa";
export var controlbutton = "main-module--controlbutton--bkPwQ";
export var donationCard = "main-module--donationCard--QpLlC";
export var resetButtonContainer = "main-module--resetButtonContainer--q+t9U";
export var submitButtonContainer = "main-module--submitButtonContainer--YZEsA";
export var sumContainer = "main-module--sumContainer--zfSfA";