import React, { useState, useEffect }  from "react"
import withUserData from "../withUserData"
import { api_base_url, browserstring, getCurrentUser, handleLoginAdmin } from "../../utils/auth"
import * as styles from "./main.module.css"
import styled from "styled-components"
import { Link } from "gatsby"
import InfoBlock from "../info-block"
import DonationForm from "../DonationForm"
import DonatorBalance from "../DonatorBalance"
import SubscriptionForm from "../SubscriptionForm"
import { useNavigationDispatch } from "../navigation-context"
import withLocation from "../withLocation"
import { Router } from "@reach/router"
import withRecipientData from "../withRecipientData"
import { ErrorMessage, Field } from "formik"
import ShareEmail from "../share-email"
import { Repeat } from "react-feather"
import tippy from "tippy.js"
import PropTypes from "prop-types"

const axios = require('axios').default;

const _ = require('lodash');

const TextContainer = styled.div`
  margin: 0;
  padding: 0.25rem;
`

const LeftAlignContainer = styled.div`
  text-align: left;
`

const RecipientsLink = styled.p`
  font-size: 0.7em;
`

const getPackageSelectionItemSource = (subscriptionType, subscriptionPackage,
                                       selectedSubscription, subscriptionIsRecurring,
                                       subscriptionFormIsSubmitting,
                                       subscriptionSelectionHandler,
                                       recurringTogglerHandler,
                                       submitSubscriptionHandler) => {
  const isSelected = selectedSubscription === subscriptionType.typeName + "~~~" + subscriptionPackage.packageName;
  const recurringButtonColor = subscriptionIsRecurring ? 'green': 'red';
  const recurringText = subscriptionIsRecurring ? "(Förnyas automatiskt)": "(Förnyas inte automatiskt)";
  return (
    <div key={subscriptionType.typeName + " - " + subscriptionPackage.packageName}
         className={styles.subscriptionButtonWrapper}>
      <div><input
        name={subscriptionType.typeName + " - " + subscriptionPackage.packageName} type="checkbox"
        value={subscriptionType.typeName + "~~~" + subscriptionPackage.packageName}
        checked={selectedSubscription === subscriptionType.typeName + "~~~" + subscriptionPackage.packageName}
        data-amount={subscriptionPackage.price}
        onChange={subscriptionSelectionHandler}/></div>
      <div
        className={isSelected ? styles.boldinfo : ""}>
        {subscriptionType.typeName + " - " + subscriptionPackage.packageName}
        {subscriptionPackage.recurringOption ? (
          <span className={styles.recurringText}>
            <Repeat
              name={'Repeat'}
              data-tippy-theme="light-border"
              className={['ml-2', 'mr-2'].join(" ")}
              strokeWidth={3}
              color={isSelected ? recurringButtonColor : "grey"}
              size={16}
              onClick={recurringTogglerHandler}/>
            {isSelected ? (recurringText) : null}
              </span>
        ):null}
      </div>
      <div>
        <button className={styles.b_r_subscribe}
                disabled={subscriptionFormIsSubmitting || selectedSubscription !== subscriptionType.typeName + "~~~" + subscriptionPackage.packageName}
                value={selectedSubscription} onClick={submitSubscriptionHandler}>
          <div className="text-center">
            <div>{subscriptionPackage.price} kr</div>
          </div>
        </button>
      </div>
    </div>
  )
}



const StoreFrontSubscriptionSelection = ({ sel, origin, sitename, siteid, siteurl,
                                           subscriptionTypes, subscriptionSubmitHandler }) => {

  const [a, setAmount] = useState(0);
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [subscriptionIsRecurring, setSubscriptionIsRecurring] = useState(true);
  const [tippyInstances, setTippyInstances] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shareEmail, setShareEmail] = useState(true);

  const packageSelectionItems = [];
  let originalPackageSelectionItem = null;

  useEffect(() => {
    updateAmount(selectedSubscription);
    updateRecurringButtonsTooltips(subscriptionIsRecurring);
  }, [subscriptionTypes])


  const updateAmount = (sub) => {
    if (sub && sub.length > 0) {
      for (const subscriptionType of subscriptionTypes) {
        for (const subscriptionPackage of subscriptionType.packages) {
          if (sub === subscriptionType.typeName + "~~~" + subscriptionPackage.packageName) {
            setAmount(subscriptionPackage.price)
          }
        }
      }
    }
  }

  const updateRecurringButtonsTooltips = (isRecurring) => {
    const textContent = isRecurring ?
      "Klicka här för att ändra till engångsprenumeration":
      "Klicka här för att göra prenumerationen återkommande";

    let allInstances = tippyInstances;
    if (allInstances === null ||allInstances.length === 0) {
      allInstances = tippy(document.getElementsByName('Repeat'));
    }

    for (const instance of allInstances) {
      instance.setContent(textContent);
    }
    setTippyInstances(allInstances);
  }

  const toggleRecurringState = (event) => {

    updateRecurringButtonsTooltips(!subscriptionIsRecurring);
    setSubscriptionIsRecurring(!subscriptionIsRecurring);
  }

  const handleSubscriptionSelection = (event) => {
    const target = event.target
    const value = target.value
    setSelectedSubscription(value)

    setAmount(parseFloat(target.dataset.amount));
  }

  const submitSubscription = (e) => {

    setIsSubmitting(true);
    setTimeout(() => {
      subscriptionSubmitHandler(siteid, selectedSubscription, subscriptionIsRecurring, shareEmail, a, setIsSubmitting).then(function(response) {

        setIsSubmitting(false);
      });

    }, 400)

  }

  if (subscriptionTypes) {
    for (const subscriptionType of subscriptionTypes) {
      for (const subscriptionPackage of subscriptionType.packages) {
        if (subscriptionType.typeName + "~~~" + subscriptionPackage.packageName === sel) {
          originalPackageSelectionItem =
            getPackageSelectionItemSource(subscriptionType, subscriptionPackage,
              selectedSubscription, subscriptionIsRecurring, isSubmitting,
              handleSubscriptionSelection, toggleRecurringState,
              submitSubscription);
        } else {
          packageSelectionItems.push(
            getPackageSelectionItemSource(subscriptionType, subscriptionPackage,
              selectedSubscription, subscriptionIsRecurring, isSubmitting,
              handleSubscriptionSelection, toggleRecurringState,
              submitSubscription)
          )
        }
      }
    }
  }

  const buttonConfirmText = () => {
    if (selectedSubscription) {
      return "Bekräfta köp av " + selectedSubscription.replace("~~~", ", ")
    } else {
      return "Ingen prenumeration vald"
    }
  }

  return (
    <div>
      <p className={["px-5", styles.textCenter].join(" ")}>
        Bekräfta och beställ din prenumeration av {sitename}.
      </p>

      {originalPackageSelectionItem &&
      <div>
        <p className="px-5">

        </p>
        <div className="m-0 mb-1">
          {originalPackageSelectionItem}
        </div>

        {packageSelectionItems.length > 0 &&

        <p className={["px-5", styles.textCenter].join(" ")}>
          Klicka på den gröna knappen för att bekräfta och beställa din prenumeration
          eller välj en annan prenumerationstyp nedan.
        </p>
        }
      </div>
      }

      <div className="m-0 mb-3">
        {packageSelectionItems}
      </div>

      <div className="m-0 mb-3">
        <ShareEmail checked={shareEmail} setChecked={setShareEmail} recipient_name={sitename} />
      </div>

      <div className="m-0 mb-3">
        <div className={styles.buttonContainer}>

          <div className={[styles.sumContainer, styles.controlbutton].join(" ")}>
            <button data-tippy-theme="light-border" data-tippy-content="Klicka här för att köpa vald prenumeration"
                    id="submitbtn"
                    className={styles.b_r_subscribe_wider} disabled={isSubmitting || a < 1} value={selectedSubscription}
                    onClick={submitSubscription}>
              {isSubmitting ? (
                  <div className="spinner-border" role="status">
                  </div>) :
                (
                  <div className={"text-left"}>
                    <div className={styles.buttonconfirmtext}>{buttonConfirmText()}</div>
                    <div>Belopp: {a} kr</div>
                  </div>
                )}

            </button>
          </div>

        </div>
      </div>

    </div>
  )

}


SubscriptionForm.propTypes = {
  sub: PropTypes.string,
  sitename: PropTypes.string,
  siteid: PropTypes.string,
  siteurl: PropTypes.string,
  subscriptionTypes: PropTypes.array,
  subscriptionSubmitHandler: PropTypes.func,
}

SubscriptionForm.defaultProps = {
  sub: ``,
  sitename: ``,
  siteid: ``,
  siteurl: ``,
  subscriptionTypes: [],
}

export default StoreFrontSubscriptionSelection
