import React, { useState, useEffect }  from "react"
import * as styles from "./main.module.css"
import styled from "styled-components"

import SubscriptionForm from "../SubscriptionForm"

import PropTypes from "prop-types"

const axios = require('axios').default;

const _ = require('lodash');

const TextContainer = styled.div`
  margin: 0;
  padding: 0.25rem;
`

const LeftAlignContainer = styled.div`
  text-align: left;
`

const RecipientsLink = styled.p`
  font-size: 0.7em;
`


const StoreFrontPurchaseSelection = ({ search, pid, origin, sitename, siteid, siteurl,
                                           products, selectedProduct, purchaseSubmitHandler, enabled=true }) => {

  const [a, setAmount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selected, setSelected] = useState(null);


  useEffect(() => {
    console.log("CHECK 1");
    console.log(selectedProduct);
    updateAmount(selectedProduct);
  }, [products])


  const updateAmount = (prod) => {
    console.log("CHECK 2");
    console.log(products);
    if (prod) {
      for (const p of products) {
        if (p.productId === prod.productId) {
          console.log("SET AMOUNT TO " + p.price);
            setAmount(p.price);
            setSelected(p);
          }

      }
    }
  }


  const submitPurchase = (e) => {

    setIsSubmitting(true);
    setTimeout(() => {
      const productIsGeneric = (search.type !== null && search.type === "generic");
      purchaseSubmitHandler(siteid,
          selected.productId,
          a,
          search.uid,
          productIsGeneric,
          setIsSubmitting).then(function(response) {

        setIsSubmitting(false);
      });

    }, 800)

  }


  return (
      <div>
        <p className="px-5">
          Bekräfta betalning av {(selected) ?
              (<span className={styles.boldinfo}>{selected.description}</span>) :
              null } genom att klicka på den gröna knappen.
        </p>
        <p className="px-5">
          Stäng denna sida/flik om du inte önskar gå vidare med ditt köp.
        </p>

        <p className="px-5" hidden={enabled}>
          Du måste verifiera din epostadress innan det går att klicka på knappen för att genomföra betalningen.
        </p>

        <div className="m-0 mb-3">

          <div className={styles.buttonContainer}>

            <div className={[styles.sumContainer, styles.controlbutton].join(" ")}>
              <button data-tippy-theme="light-border"
                      data-tippy-content="Klicka här för att genomföra köpet" id="submitbtn"
                      className={styles.b_r_donate}
                      disabled={isSubmitting  || !enabled} value={a} onClick={submitPurchase}>
                {isSubmitting ? (
                        <div className="spinner-border" role="status">
                        </div>) :
                    (
                        <div className={"text-left"}>
                          <div className={styles.buttonconfirmtext}>Genomför</div>
                          <div>Belopp: {a} kr</div>
                        </div>
                    )}

              </button>
            </div>

          </div>
        </div>

      </div>
  )

}


SubscriptionForm.propTypes = {
  sub: PropTypes.string,
  sitename: PropTypes.string,
  siteid: PropTypes.string,
  siteurl: PropTypes.string,
  subscriptionTypes: PropTypes.array,
  subscriptionSubmitHandler: PropTypes.func,
}

SubscriptionForm.defaultProps = {
  sub: ``,
  sitename: ``,
  siteid: ``,
  siteurl: ``,
  subscriptionTypes: [],
}

export default StoreFrontPurchaseSelection
