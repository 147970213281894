import React, { useState, useEffect }  from "react"
import withUserData from "../withUserData"
import { api_base_url, browserstring, getCurrentUser, handleLoginAdmin } from "../../utils/auth"
import * as styles from "./main.module.css"
import styled from "styled-components"
import { Link } from "gatsby"
import InfoBlock from "../info-block"
import DonationForm from "../DonationForm"
import DonatorBalance from "../DonatorBalance"
import SubscriptionForm from "../SubscriptionForm"
import { useNavigationDispatch } from "../navigation-context"
import withLocation from "../withLocation"
import { Router } from "@reach/router"
import withRecipientData from "../withRecipientData"
import { ErrorMessage, Field } from "formik"
import ShareEmail from "../share-email"
import { Repeat } from "react-feather"
import tippy from "tippy.js"
import PropTypes from "prop-types"

const axios = require('axios').default;

const _ = require('lodash');

const TextContainer = styled.div`
  margin: 0;
  padding: 0.25rem;
`

const LeftAlignContainer = styled.div`
  text-align: left;
`

const LeftExplanationText = styled.span`
  font-size: 0.7em;
  height: 1em;
  margin-right: 2em;
`

const RightExplanationText = styled.span`
  font-size: 0.7em;
  height: 1em;
  margin-left: 3em;
`

const MonthlyDonationCheckbox= styled.input`
  vertical-align:middle;
  margin-right: 4px;
`

const MonthlyDonationLabel= styled.label`
  word-wrap:break-word;
`

const StoreFrontDonationSelection = ({ recipient, origin, amount, donationSubmitHandler, enabled=true }) => {
  const [a, setAmount] = useState(0);
  const [isRecurring, setIsRecurring] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recipientFullName, setRecipientFullName] = useState(null);


  useEffect(() => {
    const options = {
      method: "get",
      // TODO!!! Inte hårdkoda!
      url: `${process.env.GATSBY_API_URL}/account/${recipient}`,
    }

    axios(options)
      .then(response => {
        if (response.data && response.data.RecipientName) {
          setRecipientFullName(response.data.RecipientName);
        }
      });
  }, [origin, recipient]);

  useEffect(() => {
    setAmount(Number(amount))
  }, [amount]);

  useEffect(() => {
    if (isNaN(amount)) {
      setAmount(0);
    }
  }, [amount]);


  const submitDonation = (e) => {
    setIsSubmitting(true);
    setTimeout(() => {
      donationSubmitHandler(
        recipient, a, origin, setIsSubmitting, isRecurring).then(function(response) {

        setIsSubmitting(false);
      });

    }, 400)

  }

  return (
    <div>
      <p className="px-5">
        Bekräfta donationen
        {(recipientFullName && recipientFullName.length > 0) ?
          (<span> till <span className={styles.boldinfo}>{recipientFullName}</span></span> ) :
           null } genom att klicka på den gröna knappen.
      </p>

      <div className={styles.buttonExplanationTextContainer}>
        <div>
          <LeftExplanationText>Öka beloppet med +knapparna</LeftExplanationText>
          <RightExplanationText>Ta bort belopp med 0-knappen</RightExplanationText>
        </div>
      </div>

      <div className="m-0 mb-3">

        <div className={styles.buttonContainer}>

            <button className={[styles.b_r_low, styles.controlbutton].join(" ")} value="1" onClick={(e) => setAmount(a + Number(e.target.value))}>+1 kr
            </button>
            <button className={[styles.b_r_mid, styles.controlbutton].join(" ")} value="5" onClick={(e) => setAmount(a + Number(e.target.value))}>+5 kr
            </button>
            <button className={[styles.b_r_high, styles.controlbutton].join(" ")} value="10" onClick={(e) => setAmount(a + Number(e.target.value))}>+10 kr
            </button>

          <div className={[styles.sumContainer, styles.controlbutton].join(" ")}>
            <button data-tippy-theme="light-border" data-tippy-content="Klicka här för att donera" id="submitbtn" className={styles.b_r_donate} disabled={isSubmitting  || a < 1 || !enabled} value={a} onClick={submitDonation}>
              {isSubmitting ? (
                  <div className="spinner-border" role="status">
                  </div>) :
                (
                  <div className={"text-left"}>
                    <div className={styles.buttonconfirmtext}>Bekräfta</div>
                    <div>Belopp: {a} kr</div>
                  </div>
                )}

            </button>
          </div>

          <button className={[styles.b_r_reset, styles.controlbutton].join(" ")} onClick={(e) => setAmount(0)}>0 kr
          </button>
        </div>
      </div>

      <div>
        <MonthlyDonationCheckbox id="recur" type="checkbox" value="monthly"
                                 onChange={(event) => {setIsRecurring(event.currentTarget.checked)}}
        />
        <MonthlyDonationLabel htmlFor="recur">Upprepa donationen månadsvis
        </MonthlyDonationLabel>
      </div>
    </div>
  )
}


StoreFrontDonationSelection.propTypes = {
  donationSubmitHandler: PropTypes.func,
}

StoreFrontDonationSelection.defaultProps = {

}

export default StoreFrontDonationSelection
