import React from "react"
import Layout from "../components/Layout"
import Donation from "../components/Donation"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import Status from "../components/Status"
import withLocation from "../components/withLocation"
import { Router } from "@reach/router"
import withJWT from "../components/withJWT"
import { VISITOR_SUBSCRIPTION, VISITOR_DONATION, VISITOR_PURCHASE } from "../utils/constants"
import StoreFront from "../components/StoreFront"

const Checkout = (props) => {

  const query = new URLSearchParams(props.location.search);
  const origin = query.get("origin");
  let visitorType = VISITOR_SUBSCRIPTION;
  if (query.get("ol") === "d") {
    visitorType = VISITOR_DONATION;
  } else if (query.get("ol") === "p") {
      visitorType = VISITOR_PURCHASE;
  }

  return (
    <Layout>
      <Status {...props} menuoptions={{ logout: true,
        close: origin !== "referrer",
        returnToDonation: false,
        visitorType: visitorType
      }} />
      <Router>
        <StoreFront path="/checkout" />
      </Router>
    </Layout>
  )
}

export default withJWT(Checkout)
