import React, { useEffect, useState } from "react"

import { api_base_url, browserstring, getCurrentUser, logout } from "../utils/auth"
import { navigate } from "gatsby"
import withLocation from "./withLocation"
import {USER_DONATION_RECIPIENT, USER_PURCHASE_RECIPIENT, USER_SUBSCRIPTION_RECIPIENT} from "../utils/constants";

const axios = require("axios").default
const _ = require('lodash');


const getRecipientType = (ol) => {
    if (ol && ol === "s") {
      return USER_SUBSCRIPTION_RECIPIENT;
    } else if (ol && ol === "p") {
      return USER_PURCHASE_RECIPIENT;
    } else {
      return USER_DONATION_RECIPIENT;
    }
}

const withRecipientData = ComponentToWrap => props => {
  const { rcp, sel, origin, ol, uid } = props.search
  const [siteName, setSiteName] = useState("");
  const [siteId, setSiteID] = useState("");
  const [siteSubscriptionTypes, setSiteSubscriptionTypes] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [siteUrl, setSiteUrl] = useState("");
  const [postPaymentUrl, setPostPaymentUrl] = useState("");
  const [siteCredit, setSiteCredit] = useState(0);
  const [siteProducts, setSiteProducts] = useState([]);

  const getRecipientInfo = () => {
    if (ol && ol === 'd') {
      return {
        recipientType: getRecipientType(ol),
        recipientid: rcp
      }
    } else  if (ol && ol === 'p') {
      return {
        recipientType: getRecipientType(ol),
        sitename: siteName,
        siteid: siteId,
        siteurl: siteUrl,
        site_credit: siteCredit,
        products: siteProducts,
        post_payment_url: postPaymentUrl,
        uid: uid
      }
    } else {
      return {
        recipientType: getRecipientType(ol),
        sitename: siteName,
        siteid: siteId,
        siteurl: siteUrl,
        site_credit: siteCredit,
        site_subscription_types: siteSubscriptionTypes,
        active_subscriptions: activeSubscriptions
      };
    }
  }

  const getProducts = (fetchCompletedCallback=undefined) => {
    const fetchSiteProducts= (dataCallback) => {

      const options = {
        method: "get",
        url: `${api_base_url()}/site/${rcp}/products`,
      }

      axios(options)
          .then(response => {
            if (response.status === 204) {
              dataCallback([])
            } else {
              dataCallback(response.data)
            }
          })
          .catch(error => {
            console.warn(error)
          })
    }

    fetchSiteProducts((data) => {
      if (data) {
        setSiteName(data.siteName);
        setSiteID(data.siteId);
        setSiteUrl(data.url);
        setPostPaymentUrl(data.postPaymentUrl);
        setSiteCredit(data.siteCredit);
        setSiteProducts(data.products);
      } else {
        setSiteName("");
        setSiteID("");
        setSiteUrl("");
        setPostPaymentUrl("")
        setSiteCredit(0);
        setSiteProducts([]);
      }

      if (fetchCompletedCallback) {
        fetchCompletedCallback(data);
      }
    })

  }

  const getRecipientProducts = (fetchCompletedCallback=undefined) => {
    let data = {}
    if (getRecipientType(ol) === USER_DONATION_RECIPIENT) {

    } else if (getRecipientType(ol) === USER_PURCHASE_RECIPIENT) {
      getProducts(fetchCompletedCallback);
    } else if (getRecipientType(ol) === USER_SUBSCRIPTION_RECIPIENT) {
      getSubscriptions(fetchCompletedCallback);
    }
    return data;
  }

  const getSubscriptions = (fetchCompletedCallback=undefined) => {
    const userdata = getCurrentUser();
    const fetchProductTypes = (dataCallback) => {

      const options = {
        method: "post",
        url: `${api_base_url()}/site/${rcp}/products`,
        data: {
          BrowserData: browserstring(),
          PageUrl: origin,
        },
      }

      /// If logged in user, then need to set cookieid in the request
      if (!_.isEmpty(userdata)) {
        _.set(options, 'data.CookieID', userdata.cookie);
      }

      axios(options)
        .then(response => {
          if (response.status === 204) {
            dataCallback([])
          } else {
            dataCallback(response.data)
          }
        })
        .catch(error => {
          console.warn(error)
        })
    }

    fetchProductTypes((data) => {
      if (data) {
        setSiteName(data.siteName);
        setSiteID(data.siteId);
        setSiteUrl(data.url);
        setPostPaymentUrl(data.postPaymentUrl);
        setSiteCredit(data.siteCredit);
        setSiteSubscriptionTypes(data.subscriptionTypes);
        setActiveSubscriptions(data.ActiveSubscriptions);
      } else {
        setSiteName("");
        setSiteID("");
        setSiteUrl("");
        setPostPaymentUrl("");
        setSiteCredit(0);
        setSiteSubscriptionTypes([]);
        setActiveSubscriptions([]);
      }

      if (fetchCompletedCallback) {
        fetchCompletedCallback(data);
      }
    })
  }

  useEffect(() => {

    if (getRecipientType(ol) === USER_DONATION_RECIPIENT) {

    } else if (getRecipientType(ol) === USER_PURCHASE_RECIPIENT) {
      getProducts();
    } else if (getRecipientType(ol) === USER_SUBSCRIPTION_RECIPIENT) {
      getSubscriptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
      <ComponentToWrap
        {...props}
        getRecipientProductsFunc={getRecipientProducts}
        getRecipientInfoFunc={getRecipientInfo}

      />
    )

}

export default withRecipientData