import React, { useState, useEffect, useCallback } from "react"
import withUserData from "../withUserData"
import styled from "styled-components"
import withLocation from "../withLocation"
import StoreFrontUser from "../StoreFrontUser"
import StoreFrontSubscriptionSelection from "../StoreFrontSubscriptionSelection"
import { api_base_url, browserstring, getCurrentUser, setUserData } from "../../utils/auth"
import * as yup from 'yup';
import { useNavigationDispatch } from "../navigation-context"
import { Form } from "formik"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { Link, navigate } from "gatsby"
import Card from "react-bootstrap/Card"
import StoreFrontDonationSelection from "../StoreFrontDonationSelection"
import { ref } from "yup"
import DonatorBalance from "../DonatorBalance"
import StoreFrontPurchaseSelection from "../StoreFrontPurchaseSelection";
import useInterval from "@restart/hooks/useInterval";
import {USER_DONATION_RECIPIENT, USER_PURCHASE_RECIPIENT, USER_SUBSCRIPTION_RECIPIENT} from "../../utils/constants";
import withRecipientData from "../withRecipientData";

const _ = require('lodash');
const axios = require('axios').default;


const TextContainer = styled.div`
  margin: 0;
  padding: 0.25rem;
`

const CenteredContainer = styled.div`
  text-align: center;
  padding: 1rem 1.2rem 1rem 1.2rem;
`

const RecipientsLink = styled.p`
  font-size: 0.7em;
`

const InfoBox = styled.div`
  margin: auto;
  text-align: center;
  width: 60%;
  font-size: 0.7em;
`

const InfoBorder = styled.div`
  border: 2px solid rgba(10, 124, 143, 0.5);
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
`

const StoreFront = ({ search, location, userData, userEmail, registrationFunc,
                      loginFunc, reloadUserFunc, getRecipientInfoFunc,
                      getRecipientProductsFunc, getVisitorTypeFunc}) => {

  const [email, setEmail] = useState("");
  const [selectedUsername, setSelectedUsername] = useState("");
  const [loggedInUser, setLoggedInUser] = useState(undefined);
  const [password, setPassword] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState("");
  const [passwordIsInvalid, setPasswordIsInvalid] = useState("");
  const [selectedUsernameIsInvalid, setSelectedUsernameIsInvalid] = useState("");
  const [passwordLogin, setPasswordLogin] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageTitle, setErrorMessageTitle] = useState("");
  const [infoMessages, setInfoMessages] = useState([]);
  const [donationSource, setDonationSource] = useState("");
  const [displayReturnLink, setDisplayReturnLink] = useState(false);
  const [displayCloseLink, setDisplayCloseLink] = useState(false);
  const [balance, setBalance] = useState(-1);
  const [processDelayed, setProcessDelayed] = useState(false);

  const dispatch = useNavigationDispatch();

  const [showCreditWarning, setShowCreditWarning] = useState(false);
  const [creditWarningData, setCreditWarningData] = useState({});
  const [delayedSubscriptionSubmit, setDelayedSubscriptionSubmit] = useState({});
  const [delayedPurchaseSubmit, setDelayedPurchaseSubmit] = useState({});


  const removeInfoMessage = (message) => {
    let array = [...infoMessages]; // make a separate copy of the array
    let index = array.indexOf(message);

    if (index > -1) {
      array.splice(index, 1);
      setInfoMessages(array);
    }
  }

  const addInfoMessage = (message) => {

    let messageExists = false;

    if (infoMessages && infoMessages.length > 0) {
      for (let mess of infoMessages) {

        if (message === mess) {
          messageExists = true;
        }
      }
    }

    if (!messageExists) {
      setInfoMessages([...infoMessages, message] )
    }
  }

  const handleCloseCreditWarning = (value) => {
    // Tre fall:
    // 1. Genomför köp
    // 2. Redirect till påfyllning
    // 3. Bara stäng dialogrutan

    setShowCreditWarning(false);

    if (value === "credit") {
      if (!_.isEmpty(delayedSubscriptionSubmit)) {
        delayedSubscriptionSubmit.setIsSubmitting(true);
        subscriptionSubmitHandler(
          delayedSubscriptionSubmit.siteid,
          delayedSubscriptionSubmit.selectedSubscription,
          delayedSubscriptionSubmit.subscriptionIsRecurring,
          delayedSubscriptionSubmit.shareEmail,
          delayedSubscriptionSubmit.amount,
          delayedSubscriptionSubmit.setIsSubmitting,
          false).then(() => {
          delayedSubscriptionSubmit.setIsSubmitting(false);
          setDelayedSubscriptionSubmit({});
        }).catch(() => {
          delayedSubscriptionSubmit.setIsSubmitting(false);
          setDelayedSubscriptionSubmit({});
        })
      }

      if (!_.isEmpty(delayedPurchaseSubmit)) {
        delayedPurchaseSubmit.setIsSubmitting(true);
        purchaseSubmitHandler(
            delayedPurchaseSubmit.siteid,
            delayedPurchaseSubmit.selectedProductId,
            delayedPurchaseSubmit.amount,
            delayedPurchaseSubmit.reference,
            delayedPurchaseSubmit.productIsGeneric,
            delayedPurchaseSubmit.setIsSubmitting,
            false).then(() => {
          delayedPurchaseSubmit.setIsSubmitting(false);
          setDelayedPurchaseSubmit({})
        }).catch(() => {
          delayedPurchaseSubmit.setIsSubmitting(false);
          setDelayedPurchaseSubmit({})
        })
      }
    } else if (value === "refill") {
      navigate(`/app/refill`);
    } else {
      // Do nothing. Modal dialog will be closed
    }

  }

  const handleShowCreditWarning = () => setShowCreditWarning(true);


  let schema = yup.object().shape({
    email: yup.string().email().required(),
  })

  const userIsVerified = (userdata) => {
    return (!_.isEmpty(userdata) && !_.isEmpty(userdata.Profile) && userdata.Profile.Verified === "True");
  }

  useEffect(() => {
    setLoggedInUser(userData);

    if (userData && userData.Account_A) {
      setBalance(userData.Account_A.Balance);
    }

  }, [userData])


  // useInterval(() => {
  //   console.log("CHECKING USER");
  //   console.log(userData);
  //   if (!userData || Object.keys(userData).length === 0) {
  //     console.log("RELOADING USER");
  //     reloadUserFunc();
  //   } else {
  //     setLoggedInUser(userData);
  //
  //     if (userData && userData.Account_A) {
  //       setBalance(userData.Account_A.Balance);
  //     }
  //
  //     if (!userIsVerified(userData)) {
  //       reloadUserFunc();
  //     }
  //   }
  //
  // }, 2000, userIsVerified(loggedInUser))

  useEffect(() => {

    if (search.origin === "referrer") {

      if (!document.referrer || document.referrer === "") {
        setDonationSource("Not provided");
        setDisplayReturnLink(false);
      } else {
        setDonationSource(document.referrer);
        setDisplayReturnLink(true);
      }
    } else {
      setDonationSource(search.origin);
    }

    //TODO
    // ÄR den här nödvändig?
    dispatch({type: 'donationpage', target: `${location.pathname}${location.search}`})

    if (loggedInUser && loggedInUser.Account_A) {
      setBalance(loggedInUser.Account_A.Balance);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.origin])


  // Method called after submission of subscription
  const subscriptionSubmitted = useCallback((auto_close, source, error, subscription_status="new") => {

    let timeout_msecs = 3000;
    let parent_mess = "DonationCompleted";
    if (error) {
      setErrorMessage(error);
      timeout_msecs = 7000;
      parent_mess = "DonationPending";
    } else {
      setErrorMessage(undefined);
    }

    setIsFinished(true);
    setSubscriptionStatus(subscription_status);

    setDisplayCloseLink(!auto_close)

    if (auto_close && source !== "Not provided") {
      if (displayReturnLink) {
        setTimeout(function() {
          window.location.replace(source);
        }, timeout_msecs);
      } else {
        try {
          window.parent.postMessage(parent_mess, source);
          //window.postMessage("DonationCompleted", source);
        } catch (err) {
          console.warn("Failed to call parent element")
        }
      }
    }
  },[displayReturnLink]);


  // - Validating email
  // - Creating new user if email address not in use
  // - Toggle password login if email address is already in use
  // - Return true if user data is stored and ready to be used
  const prepare_user_handler = async (userData, email, amount=0) => {
    try {
      await schema.validate({ email: email});
      setEmailIsInvalid("");
    }
    catch(err) {
      setEmailIsInvalid("Du måste ange en giltig epostadress");
      return false;
    }

    if(_.isEmpty(userData)) {
      try {
        // If the user is not logged in we need to register a new user
        userData = await registrationFunc(email, selectedUsername ? selectedUsername : email);

        addInfoMessage("<p>Ett mail har skickats till dig med en länk som du måste klicka på för att bekräfta din registrering " +
            "och aktivera ditt konto. Du har en timme på dig att klicka på länken.</p>" +
            "~~~Välkommen som ny användare i MediaLinq");

        if (userData && userData.Account_A) {
          setBalance(userData.Account_A.Balance);
        }

        let recipient = getRecipientInfoFunc();
        if (recipient.recipientType === USER_SUBSCRIPTION_RECIPIENT &&
          amount > recipient.site_credit+userData.Account_A.Balance) {
          setErrorMessage("Kan inte genomföra köpet. " +
            "Täckning saknas och din kredit som ny användare räcker inte " +
            "för att täcka beloppet. För att genomföra prenumerationen " +
            "måste du först fylla på ditt MediaLinq-konto.");
          return false;
        } else if (recipient.recipientType === USER_PURCHASE_RECIPIENT &&
            amount > recipient.site_credit+userData.Account_A.Balance) {
          setErrorMessage("Kan inte genomföra köpet. " +
              "Täckning saknas och din kredit som ny användare räcker inte för att täcka beloppet. För att" +
              "genomföra köpet måste du fylla på ditt MediaLinq-konto.");
          return false;
        }
      } catch (error) {
        if (error.response.data.includes("Username")) {
          setErrorMessage("Användarnamnet är redan upptaget");
          return false;
        }

        if (error.response.status === 409) {
          // TODO!!!!
          // User already exists, redirect to login page
          setPasswordLogin(true);
        } else {
          setEmailIsInvalid("Kunde inte registrera med den angivna epostadressen");
        }
        return false;
      }
    }
    return true;
  }

  // Method that checks if current user balance covers the specified amount
  // Returns the spread between credit and debit
  const checkUserBalance = async (amount) => {
    const { username, cookie } = getCurrentUser();

    const options = {
      method: "post",
      url: `${api_base_url()}/${cookie}/profile`,
      data: {
        BrowserData: browserstring(),
      },
    }

    const resp = await axios(options);
    const balance = resp.data.Account_A.Balance;
    let credit = amount - balance;
    if (credit > 0) {
      return {debit: balance, credit: credit}
    } else {
      return {debit: amount, credit: 0}
    }

  }

  const finalizeDonation = async (recipient, amount, reference, isRecurring=false) => {
    setIsBusy(true);
    try {
      const { username, cookie } = getCurrentUser();
      let don_resp = await axios({
        method: 'post',
        url: `${api_base_url()}/donation`,
        data: {
          CookieID: cookie,
          BrowserData: browserstring(),
          recipient_account_id: recipient,
          amount: amount,
          source_reference: reference,
          donation_recurring: isRecurring ? "1m" : ""
        }
      });
      if (don_resp.status === 200) {
        setErrorMessage("");
        subscriptionSubmitted(true, donationSource, undefined, "donation")
      } else {
        // TODO! Hantera felfall och visa felmeddelanden
        subscriptionSubmitted(false, donationSource, "Donationen misslyckades. Inga pengar har dragits från ditt konto.", "fail");
      }
    } catch(error) {
      if (error.response.status === 402) {
        setErrorMessageTitle("Din donation har ännu inte genomförts");
        subscriptionSubmitted(false, donationSource, "Du saknar täckning. Donationen har lagts som väntande och kommer att genomföras när du fyller på ditt MediaLinq-konto.", "donation");
      } else if (error.response.status === 403) {
        setErrorMessageTitle("Din donation har ännu inte genomförts");
        subscriptionSubmitted(false, donationSource, "Donationen har lagts som väntande tills dess att du verifierat din epostadress. Detta gör du genom att klicka på länken i det mail som har skickats till den epostadress du angett.", "donation");
      } else {
        subscriptionSubmitted(false, donationSource, "Donationen misslyckades. Inga pengar har dragits från ditt konto.", "fail");
      }
    } finally {
      setIsBusy(false);
    }
  }

  const finalizeSubscription = async (siteid, selectedSubscription, subscriptionIsRecurring, shareEmail) => {
    setIsBusy(true);
    try {
      const { username, cookie } = getCurrentUser();

      try {
        let sub_resp = await axios({
          method: "post",
          url: `${api_base_url()}/donation`,
          data: {
            CookieID: cookie,
            BrowserData: browserstring(),
            PageUrl: search.origin,
            NewPackage: selectedSubscription,
            SubscriptionRecurring: subscriptionIsRecurring,
            SiteID: siteid,
            ShareEmail: shareEmail,
          },
        });
        if (sub_resp.status === 200) {
          subscriptionSubmitted(true, donationSource)
        } else {
          // TODO! Hantera felfall och visa felmeddelanden
          subscriptionSubmitted(false, donationSource, "Prenumerationen misslyckades. Inga pengar har dragits från ditt konto.", "fail")
        }
      } catch (err) {
        // TODO! Hantera felfall och visa felmeddelanden

        // If response is 403 this means that it is a new user that has not yet verified the account
        if (err.response.status === 403) {
          addInfoMessage("<p>Din prenumeration har registrerats men avvaktar att " +
            "du bekräftar din epostadress. Detta gör du genom att klicka på länken " +
            "i det meddelande som skickats till " + username + ".</p>" +
            "<p>Om du inte har fått något epostmeddelande vänligen " +
            "kontrollera ditt skräppostfilter.</p>~~~Prenumerationen är avvaktande");
          setIsFinished(true);
        } else if (err.response.status === 409) {
          addInfoMessage("<p>Du har redan en giltig prenumeration på denna sida.</p>" +
            "~~~Du har redan en giltig prenumeration");
          setIsFinished(true);
        } else if (err.response.status === 402) {
          subscriptionSubmitted(false, donationSource, "Du har inte tillräckligt med pengar för att köpa prenumerationen. " +
            "Fyll på ditt <a href='/app/profile'>MediaLinq-konto</a> för att slutföra prenumerationen.", "fail")
        } else {
          subscriptionSubmitted(false, donationSource, "Prenumerationen misslyckades. Inga pengar har dragits från ditt konto.", "fail")
        }
      }
    } finally {
      setIsBusy(false);
    }
  }

  const finalizePurchase = async (siteid, productId, amount, productIsGeneric, reference) => {
    setIsBusy(true);
    try {
      const { username, cookie } = getCurrentUser();

      let recipient = getRecipientInfoFunc();
      const selected_product = {
        id: productId,
        qty: 1
      }

      console.log("FINALIZE PURCHASE!");
      console.log("Amount");
      console.log(amount);
      console.log("Reference");
      console.log(reference);
      console.log("Recipient");
      console.log(recipient);

      try {
        let sub_resp = await axios({
          method: "post",
          url: `${api_base_url()}/donation`,
          data: {
            CookieID: cookie,
            BrowserData: browserstring(),
            SiteID: siteid,
            ref: search.ref,
            Product: selected_product,
            Amount: amount,
            Reference: reference,
            uid: recipient.uid,
            genericProduct: productIsGeneric.toString()
          },
        });


        console.log("finalizePurchase RESPONSE IS: " + sub_resp.status);
        console.log(sub_resp);

        if (sub_resp.status === 200) {
          subscriptionSubmitted(false, donationSource, undefined, "purchase")
        } else {
          // TODO! Hantera felfall och visa felmeddelanden
          subscriptionSubmitted(false, donationSource, "Köpet misslyckades. Inga pengar har dragits från ditt konto.", "fail")
        }
      } catch (err) {
        console.log("ERROR!");
        console.log(err);
        // TODO! Hantera felfall och visa felmeddelanden

        // If response is 403 this means that it is a new user that has not yet verified the account
        if (err.response.status === 403) {

          addInfoMessage("<p>Innan ditt köp kan genomföras måste du bekräfta din epostadress och sedan föra över " +
              "pengar till ditt MediaLinq-konto. För att göra detta klickar du på länken " +
              "i det meddelande som skickats till " + username + ".</p>" +
              "<p>Om du inte har fått något epostmeddelande vänligen " +
              "kontrollera ditt skräppostfilter.</p>~~~Köpet är avvaktande");
          subscriptionSubmitted(false, donationSource, undefined, "purchase")
        } else if (err.response.status === 409) {
          subscriptionSubmitted(false, donationSource, "Avbryter köpet eftersom detta köp redan är utfört.", "fail")
        } else if (err.response.status === 402) {
          subscriptionSubmitted(false, donationSource, "Ditt MediaLinq-konto saknar täckning. " +
              "Fyll på ditt <a href='/app/profile'>MediaLinq-konto</a> och gör sedan om köpet.", "fail")
        } else {
          subscriptionSubmitted(false, donationSource, "Köpet misslyckades. Inga pengar har dragits från ditt konto.", "fail")
        }
      }
    } finally {
      setIsBusy(false);
    }
  }


  const donationSubmitHandler = async (recipient, amount, reference,
                                       setIsSubmitting, isRecurring = false,
                                       askBeforeCredit = true) =>

  {
    setIsBusy(true);
    try {
      reloadUserFunc();
      setPasswordLogin(false);
      const user_is_prepared = await prepare_user_handler(loggedInUser, email, amount);
      if (!user_is_prepared) {
        setDelayedSubscriptionSubmit({
          recipient_account_id: recipient,
          amount: amount,
          source_reference: reference,
          is_recurring: isRecurring
        });
        return;
      }

      await finalizeDonation(recipient, amount, reference, isRecurring);
    } finally {
      setIsBusy(false);
    }

  }


  const subscriptionSubmitHandler = async (siteid, selectedSubscription, subscriptionIsRecurring, shareEmail,
                                           amount, setIsSubmitting, askBeforeCredit = true) =>


  {
    setIsBusy(true);
    try {
    reloadUserFunc();

    setPasswordLogin(false);

    const user_is_prepared = await prepare_user_handler(loggedInUser, email, amount);
    if (!user_is_prepared) {
      setDelayedSubscriptionSubmit({
        siteid: siteid,
        selectedSubscription: selectedSubscription,
        subscriptionIsRecurring: subscriptionIsRecurring,
        shareEmail: shareEmail,
        amount: amount,
        setIsSubmitting: setIsSubmitting,
      });
      return;
    }

    let amount_spread = await checkUserBalance(amount);
    if (askBeforeCredit && amount_spread.credit > 0) {
      setDelayedSubscriptionSubmit({
        siteid: siteid,
        selectedSubscription: selectedSubscription,
        subscriptionIsRecurring: subscriptionIsRecurring,
        shareEmail: shareEmail,
        amount: amount,
        setIsSubmitting: setIsSubmitting,
      });
      setCreditWarningData(amount_spread);
      handleShowCreditWarning();
    } else {
      await finalizeSubscription(siteid, selectedSubscription, subscriptionIsRecurring, shareEmail);
    }
    } finally {
      setIsBusy(false);
    }
  }


  const purchaseSubmitHandler = async (
      siteid, selectedProductId, amount, reference, productIsGeneric,
      setIsSubmitting, askBeforeCredit = true) =>
  {
    setIsBusy(true);
    try {
      reloadUserFunc();

      setPasswordLogin(false);

      const user_is_prepared = await prepare_user_handler(loggedInUser, email, amount);
      if (!user_is_prepared) {
        console.log("setDelayedPurchaseSubmit 1");
        console.log(reference);
        setDelayedPurchaseSubmit({
          siteid: siteid,
          reference: reference,
          selectedProductId: selectedProductId,
          amount: amount,
          productIsGeneric: productIsGeneric,
          setIsSubmitting: setIsSubmitting,
        });
        return;
      }

      let recipient = getRecipientInfoFunc();

      let amount_spread = await checkUserBalance(amount);

      console.log("Now check credit. Amount_spread is:");
      console.log(amount_spread);
      console.log("Recipient credit is " + recipient.site_credit);

      let used_credit = 0

      if ("Credit" in userData && "SEK" in userData.Credit && "total" in userData.Credit.SEK) {
        used_credit = userData.Credit.SEK.total
      }

      if (askBeforeCredit &&
          amount_spread.credit > 0 &&
          amount_spread.credit+used_credit <= recipient.site_credit) {
        console.log("setDelayedPurchaseSubmit 2");
        console.log(reference);
        setDelayedPurchaseSubmit({
          siteid: siteid,
          reference: reference,
          selectedProductId: selectedProductId,
          amount: amount,
          productIsGeneric: productIsGeneric,
          setIsSubmitting: setIsSubmitting,
        });
        setCreditWarningData(amount_spread);
        handleShowCreditWarning();
      } else {
        await finalizePurchase(siteid, selectedProductId, amount, productIsGeneric, reference);
      }

    } finally {
      reloadUserFunc().then(data => {
        console.log("-------- USER ---------");
        console.log(data);
        if (data) {
          setLoggedInUser(data);
        }
      }).finally(() => {
        setIsBusy(false);
      });
    }
  }

  const checkPageAccessTypeMatch = (accessTypes, subscriptionsToCheck) => {
    for (let accessType of accessTypes.split(",")) {
      for (let subscription of subscriptionsToCheck) {
        if (subscription.subscriptionType === accessType) {
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    if (search.pt) {
      let recipientInfo = getRecipientInfoFunc();

      if (checkPageAccessTypeMatch(search.pt, recipientInfo.active_subscriptions)) {
        subscriptionSubmitted(true, donationSource, "", "active");
      }
    }
  }, [ search.pt ])

  useEffect(() => {
    setEmail(userEmail);
  }, [userEmail])


  // useEffect(() => {
  //   if (!_.isEmpty(userData) && site_credit <= 0) {
  //     setPasswordLogin(true);
  //   }
  // }, [site_credit])


  const confirmation_text = () => {
      console.log("SUBSCRIPTION STATUS IS: " + subscriptionStatus)

    if (subscriptionStatus === "fail") {
      return "Betalningen misslyckades!"
    } else if (subscriptionStatus === "donation") {
      return "Tack för donationen!"
    } else if (subscriptionStatus === "purchase") {
      return "Tack för betalningen!"
    } else if (subscriptionStatus === "active") {
      return "Du har en aktiv prenumeration. Tack för ditt stöd!"
    } else {
      return "Tack för prenumerationen!"
    }
  }

  const login_failed_callback = () => {
    setPasswordIsInvalid("Felaktigt lösenord")
    setIsBusy(false);
  }


  const check_if_delayed_donation = () => {

    if (!_.isEmpty(delayedSubscriptionSubmit) && 'recipient_account_id' in delayedSubscriptionSubmit) {
      setProcessDelayed(true);

      finalizeDonation(delayedSubscriptionSubmit.recipient_account_id, delayedSubscriptionSubmit.amount,
        delayedSubscriptionSubmit.source_reference, delayedSubscriptionSubmit.is_recurring).then(() => {

        setDelayedSubscriptionSubmit({});

        setProcessDelayed(false);

      });
    }
  }

  const check_if_delayed_purchase = (data) => {

    // If there is a pending purchase then try to submit it
    if (!_.isEmpty(delayedPurchaseSubmit) && 'siteid' in delayedPurchaseSubmit) {

      setProcessDelayed(true);

      finalizePurchase(
          delayedPurchaseSubmit.siteid,
          delayedPurchaseSubmit.selectedProductId,
          delayedPurchaseSubmit.amount,
          delayedPurchaseSubmit.productIsGeneric,
          delayedPurchaseSubmit.reference
      ).then(() => {
        setDelayedPurchaseSubmit({});
        setProcessDelayed(false);
      });
    }


    setPasswordLogin(false);
    setIsBusy(false);
  }

  const check_if_delayed_subscription = (data) => {
    if (checkPageAccessTypeMatch(search.pt, data.ActiveSubscriptions)) {
      subscriptionSubmitted(true, donationSource, undefined, "active");
    } else {
      // If there is a pending subscription then try to submit it
      if (!_.isEmpty(delayedSubscriptionSubmit) && 'siteid' in delayedSubscriptionSubmit) {

        setProcessDelayed(true);

        finalizeSubscription(delayedSubscriptionSubmit.siteid, delayedSubscriptionSubmit.selectedSubscription, delayedSubscriptionSubmit.subscriptionIsRecurring, delayedSubscriptionSubmit.shareEmail).then(() => {

          setDelayedSubscriptionSubmit({});

          setProcessDelayed(false);

        });


      }
    }

    setPasswordLogin(false);
    setIsBusy(false);
  }

  const login_success_callback = (username, cookieid) => {

    console.log("login_success_callback " + username);
    let recipient = getRecipientInfoFunc();

    console.log("recipient ");
    console.log(recipient);
    console.log("recipient type" + recipient.recipientType);
    if (recipient.recipientType === USER_SUBSCRIPTION_RECIPIENT) {
      getRecipientProductsFunc(check_if_delayed_subscription);
    }

    if (recipient.recipientType === USER_PURCHASE_RECIPIENT) {
      getRecipientProductsFunc(check_if_delayed_purchase);
    }

    if (recipient.recipientType === USER_DONATION_RECIPIENT) {
      check_if_delayed_donation();
    }
  }

  const login_click_handler = async (event) => {
    setIsBusy(true);
    try {
      loginFunc(email, password, login_success_callback, login_failed_callback);
    }
    finally {
        setIsBusy(false);
    }
  }

  const continue_click_handler = async (event) => {
    const user_validated = await prepare_user_handler(loggedInUser, email);
    reloadUserFunc();
    setPasswordLogin(user_validated);
  }

  // Returns the element for picking and submitting donation
  const donation_selection_element = () => {
    let recipient = getRecipientInfoFunc();
    return (<StoreFrontDonationSelection
      origin={donationSource}
      recipient={recipient.recipientid}
      amount={search.a}
      donationSubmitHandler={donationSubmitHandler}
    />);
  }

  const subscription_selection_element = () => {
    let recipient = getRecipientInfoFunc();
    if (processDelayed) {
      return (<div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Genomför prenumeration...</span>
        </div></div>)
    } else if (!_.isEmpty(loggedInUser) || recipient.site_credit > 0) {

      return(
        <StoreFrontSubscriptionSelection
          sitename={recipient.sitename}
          subscriptionTypes={recipient.site_subscription_types}
          subscriptionSubmitHandler={subscriptionSubmitHandler}
          sel={search.sel}
          origin={search.origin}
          siteid={recipient.siteid}
          siteurl={recipient.siteurl}
        />
      )
    }
  }


  const purchase_selection_element = () => {
    let recipient = getRecipientInfoFunc();
    if (processDelayed) {
      return (<div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Genomför prenumeration...</span>
        </div></div>)
    } else {

      let selectedProduct = null;
      for (const product of recipient.products) {
        if (product.productId === search.pid) {
          selectedProduct = product;
        }
      }

      console.log(selectedProduct);
      console.log("RECIPIENT");
      console.log(recipient);

      return(
          <StoreFrontPurchaseSelection
              search={search}
              sitename={recipient.sitename}
              products={recipient.products}
              purchaseSubmitHandler={purchaseSubmitHandler}
              selectedProduct={selectedProduct}
              origin={search.origin}
              siteid={recipient.siteid}
              siteurl={recipient.siteurl}
              enabled={userData && userData.Profile}
          />
      )
    }
  }


  const user_close_element = () => {
    console.log("user_close_element");
    console.log(search);
    if (displayCloseLink) {
      if (!search || !search.origin) {
        let recipient = getRecipientInfoFunc();
        if (recipient.post_payment_url) {
          let returnUrl = recipient.post_payment_url
          if (recipient.post_payment_url.slice(-1) === '?' || recipient.post_payment_url.slice(-1) === '&') {
            returnUrl = returnUrl + "uid=" + recipient.uid;
          }

          console.log("Return url to store is " + returnUrl);
          return (
              <>
                <Card.Link href={returnUrl}>Fortsätt till {recipient.sitename}</Card.Link>
                <Card.Link href="/app/profile">Fortsätt till din profilsida</Card.Link>
              </>

          )
        } else {
          return (
              <>
                Stäng denna sida! Du kan även se ditt köp på din <Link to="/app/profile">profilsida</Link>.
              </>
          )
        }
      } else {
        return (
            <a href="#" onClick={() => window.parent.postMessage("Close", search.origin)}>Stäng och återgå</a>
        )
      }
    }
  }

  const reset_error_message = () => {
    setErrorMessage(undefined);
    setErrorMessageTitle(undefined);
  }

  const user_alert_element = () => {
    let elements = [];

    if (errorMessage) {
      let header = errorMessageTitle;
      if (!header) {
        header = "Din betalning kunde inte genomföras";
      }
      elements.push(
        <Alert key="errormess" variant="danger" onClose={() => reset_error_message()} dismissible>
          <Alert.Heading>{header}</Alert.Heading>
          <p dangerouslySetInnerHTML={{__html: errorMessage}}>
          </p>
        </Alert>
      );
    }
  console.log("Rendering infomessages");
    for (const message of infoMessages) {
      let[body, header] = message.split('~~~', 2);
      const messagekey = "i" + Date.now() + Math.floor(Math.random() * 100);
      elements.push (
        <Alert key={messagekey} variant="info" onClose={() => removeInfoMessage(message)} dismissible>
          <Alert.Heading>{header}</Alert.Heading>
          <p dangerouslySetInnerHTML={{__html:body}}>
          </p>

        </Alert>
      );
    }

      return elements;
  }

  const user_continue_button_element = () => {
    let recipient = getRecipientInfoFunc();
    if (passwordLogin) {
      return(
        <Button disabled={password.length === 0 || isBusy} onClick={login_click_handler} variant="primary">Logga in</Button>
      )
    } else if (_.isEmpty(loggedInUser) && recipient.site_credit === 0)  {
      return(
         <Button variant="primary" onClick={continue_click_handler}>Fortsätt</Button>
      )
    }

  }

  const user_select_element = () => {
    if (_.isEmpty(loggedInUser)) {
      let visitorType = getVisitorTypeFunc()
      return (
        <div>
        <StoreFrontUser
          userData={loggedInUser}
          userEmail={email}
          displayPasswordLogin={passwordLogin}
          faultyEmailMessage={emailIsInvalid}
          faultyPasswordMessage={passwordIsInvalid}
          faultyUsernameMessage={selectedUsernameIsInvalid}
          setEmailCallback={setEmail}
          setPasswordCallback={setPassword}
          setUsernameCallback={setSelectedUsername}
          visitorType={visitorType}
        />

          {user_continue_button_element()}

        </div>
      )
    }
  }

  const donationpage_path = location.pathname+location.search;
  const { username, cookie } = getCurrentUser();

  return (
    <>
        {
            isBusy ? (
                <Card>
                  <Card.Body>
                    <CenteredContainer>
                      <div className="spinner-border" role="status"></div>
                    </CenteredContainer>
                  </Card.Body>
                </Card>
            )
            :
            (
            <CenteredContainer>
              <div>
                {user_select_element()}
                {user_alert_element()}
              </div>

              <Card style={{ display: isFinished || passwordLogin ? "none" : "block" }}>
                <Card.Body>
                  {search.ol === "d" ? donation_selection_element() :
                      search.ol === "p" ? purchase_selection_element() : subscription_selection_element()}
                  {username && cookie ? <div>
                  <DonatorBalance donationpage={donationpage_path} account_data={userData.Account_A} donator_name={username}
                                  cookie={cookie} />
                    </div> : ""}

                </Card.Body>
              </Card>

              <Card style={{ display: isFinished ? "block" : "none" }}>
                <Card.Body>
                  <Card.Title>{confirmation_text()}</Card.Title>


                  {displayCloseLink ?
                    <Card.Text>
                      {user_close_element()}
                    </Card.Text>
                    :
                    <Card.Text
                      style={{ display: displayReturnLink && donationSource !== "Not provided" ? "block" : "none" }}>
                      Du skickas strax tillbaka. Eller <a href={donationSource}>klicka här</a> om du inte vill vänta.
                    </Card.Text>
                  }
                </Card.Body>
              </Card>

            </CenteredContainer>
          )
        }


      <Modal show={showCreditWarning} onHide={() => handleCloseCreditWarning("close")}>
        <Modal.Header closeButton>
          <Modal.Title>Bekräfta köp på kredit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{search.ol === "p" ? "Ditt köp är på " : "Prenumerationen du valt kostar "}  {creditWarningData.debit+creditWarningData.credit} kronor och
          saldot på ditt MediaLinq-konto är {creditWarningData.debit}. Om du så önskar kan du gå vidare med köpet
          och ta överskjutande belopp ({creditWarningData.credit} kronor) på kredit för att sedan fylla på
            ditt MediaLinq-konto och betala i efterhand.
          </p>
          <p style={{display: userIsVerified(loggedInUser) ? "block" : "none" }}>
            Klicka på den blå knappen nedan för att genomföra köpet på kredit. Klicka på den
            grå knappen för att öppna en sida där du kan fylla på ditt MediaLinq-konto innan du genomför
            ditt köp.
          </p></Modal.Body>
        <Modal.Footer>
          <Button
            style={{display: userIsVerified(loggedInUser) ? "block" : "none" }}
            value={"refill"} variant="secondary" onClick={() =>handleCloseCreditWarning("refill")}>
            Öppna påfyllning
          </Button>
          <Button value={"credit"} variant="primary" onClick={() =>handleCloseCreditWarning("credit")}>
            Genomför kreditköp
          </Button>
        </Modal.Footer>
      </Modal>

      </>
    )

}
export default withLocation(withUserData(withRecipientData(StoreFront), false))
